<template>
    <b-overlay :show="showLoader">
        <b-card >
            <b-alert class="p-1 text-center" show variant="warning">Po spremembi soseske, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe niso vidne, poskusite osvežiti spletno stran.</b-alert>
            <faq-component :model="object" />
            <div class="d-flex justify-content-end mt-2">
                <b-button @click="sendEditedData()">Shrani spremembe</b-button>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
    import FaqComponent from './FaqComponent.vue'
    import {BAlert, BButton, BCard, BOverlay} from 'bootstrap-vue'
    export default {
        components: { 
            FaqComponent,
            BCard,
            BButton,
            BAlert,
            BOverlay
        },            
        data() {
            return {
                showLoader: false,
                faq_id: null,
                object: null
            }
        },
        methods:{
            async sendEditedData() {
                this.showLoader = true
                try {
                    await this.$http.patch(`/api/management/v1/faq/${this.faq_id}`, this.object)
                    this.$printSuccess('Faq je posodobljen')

                    setTimeout(() => {
                        this.$router.push({name: 'faq-list'})
                    }, 1000)
                } catch (err) {
                    this.$printError('Prislo je do napake pri dodajanju')
                } finally {
                    this.showLoader = false
                }

            },
            async loadData() {
                this.showLoader = true
                try {
                    const test = await this.$http.get(`/api/management/v1/faq/${ this.faq_id}`)
                    this.object = test.data

                } catch (err) {
                    this.$printError(err)
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.loadData()
        },
        created() {
            this.faq_id = this.$route.params.faq_id
        }
    }
</script>

<style scoped>
    .item-description-list{
        border: 2px solid grey;
        padding: 35px 10px 10px 10px;
        position: relative;
    }

    .delete-from-list{
        color: red;
        font-weight: 900;
        font-size: 17px;
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
    }
</style>